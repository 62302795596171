.bg-image-white{
    background-image: url('../../Assets/background-private-header.svg');
    background-color: #F5FAFF;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0px 0px 40px 40px !important;
}
.padding-logo-white {
    padding: 25px 10px 145px 10px!important;
}
.vertical-divider{
    border-left: 3px solid #636569;
    height: 45px;
    margin-left: 10px;
    margin-top: 8px;
}
.css-e53awj-MuiStack-root{
    justify-content: center;
}